import React, { Fragment } from 'react'
import { Global } from '@emotion/core'
import SEO from "../components/seo"
import NavBar from '../components/NavBar'
import Footer from '../components/FooterSite'
import ctaEscola from '../images/faq.png'

import { globalStyles } from '../components/layout.styles'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion'

import {
  container,
  content_about,
  about,
  videoFaq
} from '../components/styles/InternalPages.styles'

const StudentsPage = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Veredas - Caminho alternativo através do qual é possível chegar mais rápido a um determinado destino." />
    <NavBar location={props.location} />
    <div>
      <div css={about}>
        <div style={{ justifyContent: 'center', display: 'flex', marginTop: 40 }}>
          <img src={ctaEscola} alt='/' />
        </div>
        <div css={container}>
          <div css={content_about} style={{ marginTop: 60, marginBottom: 60 }}>
            <Accordion allowZeroExpanded preExpanded={['1']}>
              <AccordionItem uuid='1'>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>1. Qual a relação do Projeto Escola do Futuro com a SAPIENTINA?</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>A Olimpíada do Futuro influenciou a criação do projeto Escola para o Futuro. Como nem todos chegam na fase de criação e elaboração de projetos, o projeto Escola para o Futuro vem possibilitar que essa experiências se transforme num instrumento didático, oferecendo a escola capacitação, tecnologia e base teórica para desenvolver habilidades essenciais para o século 21, tornando o estudante protagonista do processo de ensino-aprendizagem independente do processo seletivo da Olimpíada.</p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>2. Quais os recursos que o Projeto Escola do Futuro vai disponibilizar para orientar o trabalho que será feito pelo alunos?</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <ul>
                    <li>- Aplicativo customizado com a logo da escola e material exclusivo.</li>
                    <li>- Uma plataforma com material orientando a criação de projetos através do Design Think, metodología utilizada nas escolas mais inovadoras do mundo.</li>
                    <li>- Livro 21 Lições para o Século XXI, para os alunos inscritos no projeto, que embasa teoricamente nosso projeto, ampliando o repertório cultural para o Enem.</li>
                    <li>- Formação de professores que aplicarão o projeto analisando temas como finanças, marketing, pitch (apresentação de investimentos, usada pelo mercado financeiro), compartilhando experiências com outros professores que já utilizaram esses recursos como instrumentos didáticos.</li>
                    <li>- Exercícios exclusivos sobre o Livro na plataforma estimulando o desenvolvimento de Habilidades da BNCC, oferecendo dados reais ao professor e a escola.</li>
                    <li>- Suporte na elaboração de Pitch's.</li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>3. Qual será o programa de atividades que será desenvolvido a partir do livro base do projeto?</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>Inicialmente propomos encontros semanais no contraturno para discutir o Livro e realizar exercícios na plataforma, com problematizações de temas abordados pelo Livro 21 Lições para o Século XXI. Nessa etapa os alunos terão contato com questões da atualidade, esse momento é de preparação para continuação do projeto, quando eles terão que ampliar as informações, propor soluções e aplicar suas ideias.</p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>4. Qual o produto final deste projeto? No final de 2020 quais devem ser os resultados?</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>Dessas Etapas serão produzidos projetos personalizados e passíveis de execução, como Startups, ONG’s, projetos de leis entre outros que permitam que os estudantes estejam prontos para efetivamente transformar o mundo que está e, a partir dessa experiência, desenvolver habilidades essenciais para o século XXI.</p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>5. Quando deve chegar o Livro?</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>O livro deve chegar nas escolas parceiras entre 23/03 a 27/03.</p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>6. Em que plataforma são propostos os letramentos?</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>Nossa plataforma personalizada do projeto Escola para o Futuro será disponibilizada em abril contendo exercícios exclusivos e 10 módulos de direcionamento para aplicação das etapas de Design Thinking.</p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>7. Os cursos podem ser utilizados como itinerários formativos de acordo com a BNCC? Onde obter a ementa para contar como currículo?</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>Esse projeto tem um objetivo servir de base para produção e redefinição do currículo fundamentados pela BNCC.Possibilita a exploração de zonas de   interesse dos alunos e, a partir disso, propor itinerários formativos.</p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>8. Quando e como será a formação do professor? Apenas dele ou da coordenação também? Como será o acompanhamento? Quem será a pessoa que tira as dúvidas?</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>A formação dos professores será no estilo Webinar (uma conferência online ou videoconferência com intuito educacional) de compartilhamento de experiências entre professores que já vivenciaram o projeto. O calendário desse compartilhamento de experiências será divulgado, eles acontecerão sempre com grau de antecedência do início de cada etapa. em momentos específicos e avisando previamente solicitaremos a participação de representantes da coordenação e direção da escola. teremos professores disponíveis e acessíveis para tirar dúvida e dar suporte aos professores que executaram projeto, fornecendo o contato direto entre as partes.</p>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>9. Quais as Etapas do Projeto e suas durações?</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>De uma forma geral o projeto possui três grandes etapas:</p>
                  <ul>
                    <li>1. Leitura, verificação de aprendizagem através dos exercícios na plataforma e problematização. De 01/04 a 29/05.</li>
                    <li>2. Aplicação da metodologia de Design Thinking para criação de um pré-projeto estabelecendo as áreas de atuação e os propósitos de cada equipe.De 01/06 a 27/06.</li>
                    <li>3. Elaboração de um pitch (comunicação eficiente) orientado pelos módulos de Design Thinking que estão na plataforma, possibilitando e viabilizando a execução das propostas. De 03/08 a 11/09.</li>
                  </ul>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>
                    <h3>10. As escolas recebem alguma informação final sobre a aplicação do Projeto?</h3>
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <p>A escola receberá uma pesquisa de opinião e análise qualitativa dos projetos de todo o Brasil possibilitando a criação de uma rede de informação necessária para elaboração dos Itinerários Formativos que devem ser implantados, segundo a BNCC, até 2022.</p>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion> 
          </div>
          <div css={videoFaq} style={{ marginBottom: 60 }}>
            <h2>Para saber um pouco mais, é só assistir esse filme</h2>
            <iframe title="Perguntas frequentes" src="https://player.vimeo.com/video/395291602" width="100%" height="480" frameborder="0" allow="autoplay; fullscreen" allowfullscreen />
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default StudentsPage
